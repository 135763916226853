home-page {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 50px;
  }
  

  .blogpost-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-top: 60px;
  }
  
  .blog-item {
    margin-bottom: 20px; 
    padding: 0px;
    max-width: 700px; 
    width: 100%;
    text-align: left; 
    border-radius: 5px;
  }

  @media (max-width: 600px) {
    .blog-item {
      margin-bottom: 0px; 
      padding: 10px;
      max-width: 300px; 
      width: 100%;
      text-align: left; 
      border-radius: 5px;
    }
  }
  .blog-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;

    
  }
  
  .blog-link {
    text-decoration: none;
    color: #333; 
    font-weight: bold;
    margin-bottom: 1  px;
    text-align: left; 
  }
  
  .blog-container p {
    margin: 0; 
  }
  
  .blog-item a {
    font-weight: bold;          
    text-decoration: none;     
    color: #AFDDE5;              
  }

  
