.blogpost-title {
    font-size: 24px;
    margin-bottom: 10px;
    padding-top: 60px;
}

.blogpost-meta {
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
}

.blogpost-content p {
    font-size: 16px;
    line-height: 1.6;
    max-width: 700px;
    width: 100%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 600px) {
    .blogpost-content {
      max-width: 300px;       
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
      font-size: 14px;
      line-height: 1.6;
    }
  }
